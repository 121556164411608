import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import favicon from "../../images/favicon.png";
import banner from "../../images/banner.png";
import { brandSettings } from "../StyledComponents/vars";

const SEO = ({ title, templateTitle, lang, meta, image, pathname }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, siteUrl, defaultImage, twitterUsername },
      },
      seoJson: { description, keywords },
    }) => {
      const seo = {
        description,
        image: `${siteUrl}${image || defaultImage || banner}`,
        keywords,
        title: title || defaultTitle,
        url: `${siteUrl}${pathname ? `/${pathname}` : "/"}`,
      };

      return (
        <>
          <Helmet
            htmlAttributes={{
              lang,
            }}
            link={[
              { href: `${favicon}`, rel: "shortcut icon", type: "image/png" },
            ]}
            meta={[
              {
                content: `${brandSettings.colorPrimary}`,
                name: `theme-color`,
              },
              {
                content: `${brandSettings.colorPrimary}`,
                name: `msapplication-navbutton-color`,
              },
              {
                content: `${brandSettings.colorPrimary}`,
                name: `apple-mobile-web-app-status-bar-style`,
              },
              // {
              //   property: `title`,
              //   content: seo.title,
              // },
              {
                content: seo.title,
                property: `og:title`,
              },
              {
                content: seo.keywords,
                property: `keywords`,
              },
              // {
              //   name: `description`,
              //   content: seo.description,
              // },
              {
                content: seo.description,
                property: `og:description`,
              },
              {
                content: seo.url,
                property: `og:url`,
              },
              // {
              //   property: `image`,
              //   content: seo.image,
              // },
              {
                content: seo.image,
                property: `og:image`,
              },
              {
                content: `1200`,
                property: `og:image:width`,
              },
              {
                content: `630`,
                property: `og:image:height`,
              },
              // {
              //   property: `og:site_name`,
              //   content: seo.title,
              // },
              {
                content: `website`,
                property: `og:type`,
              },
              {
                content: `summary_large_image`,
                name: `twitter:card`,
              },
              {
                content: twitterUsername,
                name: `twitter:site`,
              },
              // {
              //   name: `twitter:title`,
              //   content: seo.title,
              // },
              // {
              //   name: `twitter:description`,
              //   content: seo.description,
              // },{
              //   name: `twitter:image`,
              //   content: seo.image,
              // },
            ].concat(meta)}
            title={seo.title}
            titleTemplate={`${templateTitle} | %s`}
          />
        </>
      );
    }}
  />
);

export default SEO;

SEO.propTypes = {
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  pathname: PropTypes.string,
  templateTitle: PropTypes.string,
  title: PropTypes.string,
};

SEO.defaultProps = {
  image: null,
  lang: `en`,
  meta: [],
  pathname: null,
  templateTitle: null,
  title: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        twitterUsername
      }
    }
    seoJson {
      keywords
      description
    }
  }
`;
